import './scripts/common';
import './scripts/common-nav';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import * as ajaxForm from './scripts/ajax-form';
ajaxForm.init();

import * as ajaxFormExtend from './scripts/ajax-form-extend';
ajaxFormExtend.init();

import * as ajaxFormMap from './scripts/ajax-form-map';
ajaxFormMap.init();

import {onFind} from "@elements/init-modules-in-scope";
import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as centerActiveTab from './scripts/center-active-tab';
centerActiveTab.init();

import * as gallery from './scripts/gallery';
gallery.init();

import * as experienceSlider from './scripts/experience-slider';
experienceSlider.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();